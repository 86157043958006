<template>
  <div class="nav">
    <ul>
      <li class="item" v-for="(item, index) in hrefList" :key="index" @click="intoLocal(index)">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['hrefList'],
  data() {
    return {

    }
  },
  methods: {
    intoLocal(index) {
      console.log(index);
      if (index == 0) {
        document.documentElement.scrollTop = 0
        this.$router.push('/privacypolicy')
        return
      }
      if (index == 1) {
        document.documentElement.scrollTop = 0
        this.$router.push('/service')
        return
      }
      if (index == 2) {
        document.documentElement.scrollTop = 0
        this.$router.push('/contactus')
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./nav.scss">

</style>