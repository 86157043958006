import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TermsOfServie from '@/views/TermsOfServie/TermsOfServie'
import PrivacyPolicy from '@/views/PrivacyPolicy/PrivacyPolicy'
import ContactUs from '@/views/ContactUs/ContactUs'
import Content from '@/components/Content/Content'

import Mobile from '@/views/Mobile/Mobile'
import MobileTermsOfServie from '@/views/MobileTerOfSer/MobileTerOfSer.vue'
import MobilePrivacyPolicy from '@/views/MobilePriPo/MobilePriPo'
import MobileContacUs from '@/views/MobileContactUs/MobileContactUs'
import Mobilecontent from '@/components/MobileContent/Mobilecontent'
import Nav from '@/views/Navlist/Navlist'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'main',
        component: Content,
      },
      {
        path: '/service',
        name: 'Service',
        component: TermsOfServie
      },
      {
        path: '/privacypolicy',
        name: 'Privacypolicy',
        component: PrivacyPolicy
      },
      {
        path: '/contactus',
        name: 'contactus',
        component: ContactUs
      },
    ]
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: Mobile,
    children: [
      {
        path: '/mobile',
        name: 'Mobilecontent',
        component: Mobilecontent,
      },
      {
        path: '/mservice',
        name: 'MobileService',
        component: MobileTermsOfServie
      },
      {
        path: '/mprivacypolicy',
        name: 'MobilePrivacypolicy',
        component: MobilePrivacyPolicy
      },
      {
        path: '/mcontactus',
        name: 'MobileContacUs',
        component: MobileContacUs
      },
    ]
  },
  {
    path: '/nav',
    name: 'Nav',
    component: Nav
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
