<template>
  <div>
    <Mdetail :deteil_title="'Contact Us'" ></Mdetail>
    <div class="detail_text_box ">
      <ul class="mobconuslist">
        <li class="mobconusitem">
          <div class="mobconustitle">Communication Address</div>
          <div class="mobconusinfo">GREATLINE INNOVATIONS LIMITED.<br />
            Address: FLAT 1512, 15/F, LUCKY CENTRE,NO. 165-171 WAN CHAI ROAD,WAN CHAI,Hong Kong</div>
        </li>
        <li class="mobconusitem">
          <div class="mobconustitle">Do you have suggestions for our App?
          </div>
          <div class="mobconusinfo">Email your suggestions to <p style="color:#FF68E7">contact@baatlive.app

            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import Mdetail from '@/components/Mdetail/Mdetail.vue'
export default {
  components: {
    Mdetail
  },
}
</script>

<style lang="scss" scoped>
.mobconuslist {
  padding: .24rem 0 .22rem;

  .mobconusitem {
    text-align: left;

    .mobconustitle {
      font-size: .2rem;
      font-family: Prompt-Bold;
      font-weight: bold;
      color: #28282F;
      margin-bottom: .12rem;
    }

    .mobconusinfo {
      font-size: .16rem;
      font-family: Prompt-Regular;
      font-weight: 400;
      color: #5F6170;
      margin-bottom: .32rem;
    }
  }
}
</style>