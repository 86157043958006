<template>
  <div class="footer">
    <div class="wrap">
      <div class="gettheapp">
        <img src="../img/gettheapp.png" />
        <div class="freedownload" @click="downLoad">Free Download</div>
      </div>
      <div class="underline"></div>
      <div class="footerinfo">Watch great live streams, live chat with people worldwide</div>
      <div class="trademark">
        <div class="trademarkl">
          © 2022  Baat All Rights Reserved.
        </div>
        <div class="trademarkr">
          <p class="p1">GREATLINE INNOVATIONS LIMITED.</p>
          <p>Address: FLAT 1512, 15/F, LUCKY CENTRE,NO. 165-171 WAN CHAI ROAD,WAN CHAI,Hong Kong</p>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import downLoadAPK from '@/utils/index'
export default{
  methods: {

    downLoad(){
      downLoadAPK()
    }
  },
}
</script>

<style lang="scss" scoped src="./footer.scss">

</style>