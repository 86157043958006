<template>
  <div class="tos" @mousewheel="chslide">
    <Detail ref="header" :deteil_title="'Contact Us'" :detail_icon="require('@/views/img/detail_icon2.png')"></Detail>
    <div class="wrap ">
      <ul class="conuslist">
        <li class="conusitem">
          <div class="conustitle">Communication Address</div>
          <div class="conusinfo">GREATLINE INNOVATIONS LIMITED.<br />
            Address: FLAT 1512, 15/F, LUCKY CENTRE,NO. 165-171 WAN CHAI ROAD,WAN CHAI,Hong Kong
          </div>
        </li>
        <li class="conusitem">
          <div class="conustitle">Do you have suggestions for our App?
          </div>
          <div class="conusinfo">Email your suggestions to <span style="color:#FF68E7">contact@baatlive.app

            </span></div>
        </li>
      </ul>

    </div>
  </div>
</template>


<script>
import Detail from '@/components/Detail/Detail.vue'
export default {
  components: {
    Detail,
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 70px 0 120px;
}

.conuslist {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .conusitem {
    width: 60%;
    margin-bottom: 50px;
    text-align: center;

    .conustitle {
      color: #28282F;
      font-size: 20px;
      font-family: Prompt-SemiBold;
    }

    .conusinfo {
      font-size: 16px;
      font-family: Prompt-Regular;
      font-weight: 500;
      color: #5F6170;
    }
  }
}
</style>