<template>
  <div class="mobile_content">
    <div class="mobile_header">
      <div class="headimg">
        <img src="../img/mobileheadimg.png">
      </div>
      <div class="headbtn" @click="downLoad"></div>
    </div>
    <div class="bg1">
      <div class="livestreaming">
        <div class="mbtext_title">Live Streaming</div>
        <div class="mbinfotext">Whatever be your talent - from shayari to singing, dancing to fashion. Baatlive is your online stage to perform for your audience & build a loyal community.</div>
        <img src="../img/mobileboximg1.png">
      </div>
    </div>
    <div class="bg2">
      <div class="summary" v-for="(item, index) in summaryList" :key="index">
        <img :src="item.img" />
        <div class="mbtext_title">{{ item.texttitle }}</div>
        <div class="mbinfotext">{{ item.infotext }}</div>
      </div>
    </div>


  </div>
</template>

<script>
import downLoadAPK from '@/utils/index'
export default {
  data() {
    return {
      summaryList: [
        {
          img: require('../img/summary1.png'),
          texttitle: 'Voice Chat',
          infotext: 'A place for you to connect with like minded people from across the world. Build your own voice chatroom community and be recognised.'
        },
        {
          img: require('../img/summary2.png'),
          texttitle: 'Gifting',
          infotext: 'Liked a performance? Now you can gift your favourite influencers and streamers and be recognised by them.'
        },
        {
          img: require('../img/summary3.png'),
          texttitle: 'Influencer',
          infotext: 'You can get in touch with the top influencers from across India and Middle East.'
        },
      ]
    }
  },
  methods: {
    downLoad() {
      downLoadAPK()
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilecontent.scss">

</style>