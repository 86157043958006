<template>
  <div class="mobilefooter">
    <img class="footergettheapp" style="margin-bottom: .17rem;" src="../img/footergettheapp.png" />
    <div class="mbinfotext" style="margin-bottom: .2rem;">Watch great live streams, live chat with people worldwide
    </div>
    <div class="ftfreedownload" style="margin-bottom: .62rem;" @click="downLoad">Free Download</div>
    <div class="mbinfotext">
      <p>GREATLINE INNOVATIONS LIMITED.</p>
      <p style="margin:.08rem 0 .2rem">Address: FLAT 1512, 15/F, LUCKY CENTRE,NO. 165-171 WAN CHAI ROAD,WAN CHAI,Hong Kong</p>
      <p>© 2022 Baat All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
import downLoadAPK from '@/utils';
export default {
  methods: {
    downLoad() {
      downLoadAPK()
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilefooter.scss">

</style>