<template>
  <div class="mobileheadernav">
    <div class="logo"><img src="../img/logo.png" @click="gotohome"></div>
    <div class="navbtn" @click="gotonav"><img src="../img/mobilenavlist.png"></div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import downLoadAPK from '@/utils'
export default {
  computed: {
    ...mapState(['navshow'])
  },
  methods: {
    ...mapMutations(['changenavshow']),
    gotonav() {
      // this.$refs.shownav.style.display = 'block'
      // this.changenavshow(true)
      this.$router.push('/nav')
    },
    closenav() {
      this.changenavshow(false)
      // this.$refs.shownav.style.display = 'none'
    },
    mobchangebg() {
      let sh = document.documentElement.scrollTop
      if (sh >= 50) {
        document.getElementsByClassName('mobileheadernav')[0].classList.add('navbg')
      }
      if (sh < 50) {
        document.getElementsByClassName('mobileheadernav')[0].classList.remove('navbg')
      }
    },
    downLoad() {
      downLoadAPK()
    },
    gotohome() {
      this.$router.push('/mobile')
    },

  }
}
</script>

<style lang="scss" scoped src="./mobileheadernav.scss">

</style>