<template>
  <div class="shownav" @touchmove.prevent>
    <div class="shownav_head">
      <div class="logo">
        <img src="../img/logo.png">
      </div>
      <div class="closebtn" @click="closenav">
        <img src="../img/nav_close.png">
      </div>
    </div>
    <div class="shownav_body">
      <ul class="nav_list">
        <li class="nav_item" @click="gotoview('mprivacypolicy')"><span>Privacy Policy</span><img
            src="../img/nav_right.png" /> </li>
        <li class="nav_item" @click="gotoview('mservice')"><span>Terms Of Servie</span><img
            src="../img/nav_right.png" /> </li>
        <li class="nav_item" @click="gotoview('mcontactus')"><span>Contact Us</span><img src="../img/nav_right.png" /> </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {

  methods: {
    ...mapMutations(['changenavshow']),
    closenav(e) {
      this.$router.go(-1)
    },
    gotoview(path) {
      console.log('ddd');
      this.$router.push(`${path}`)
      document.documentElement.scrollTop = 0
    },
  }
}
</script>

<style lang="scss" scoped src="./navlist.scss">

</style>