function downLoadAPK() {
  // window.open('./apps/wakapro/wakapro_release.apk', '_blank');
  var timestamp = Date.parse(new Date());
  var apklink = `./apps/Baat-Release.apk?t=${timestamp}`;
  //----- 创建隐藏的可下载链接
  var eleLink = document.createElement('a');
  eleLink.setAttribute("href", apklink);
  eleLink.setAttribute("download", 'Baat-Release.apk.apk');
  eleLink.style.display = 'none';
  // 触发点击
  document.body.appendChild(eleLink);
  eleLink.click();
  // 然后移除
  document.body.removeChild(eleLink);
}
export default downLoadAPK