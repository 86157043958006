<template>
  <div class="content">
    <div class="header">
      <div class="hbg">
        <div class="header_body wrap">
          <img class="header_img" src="../img/header_img.png" />
          <div class="freedown" @click="downLoad"></div>
        </div>
      </div>

    </div>
    <div class="bg1">
      <div class="wrap">
        <div class="livestreaming mg wow animate__fadeInUp" data-wow-duration="0.5s" data-wow-delay="0s">
          <div class="text_title">Live Streaming</div>
          <div class="infotext">Whatever be your talent - from shayari to singing, dancing to fashion. Baatlive is your online stage to perform for your audience & build a loyal community.</div>
          <img class="livestreaming_img" src="../img/livestreaming_img.png" />
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="wrap">
        <div class="infocan mg wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
          <div class="infocanl">
            <img class="infocanimg" src="../img/infocanl.png" />
            <div class="infocantext_title">Voice Chat</div>
            <div class="infotext ">A place for you to connect with like minded people from across the world. Build your own voice chatroom community and be recognised.</div>
          </div>
          <div class="infocanr">
            <img class="infocan_i" src="../img/infocanr_i.png" />
            <img class="infocanimg" src="../img/infocanr.png" />
            <div class="infocantext_title">Gifting</div>
            <div class="infotext">Liked a performance? Now you can gift your favourite influencers and streamers and be recognised by them.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg4">
      <div class="wrap">
        <div class="influencer mg wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s" id="contact">
          <div class="influencerl">
            <img src="../img/influencerl.png" />
          </div>
          <div class="influencerr">
            <div class="text_title">Influencer </div>
            <div class="infotext">You can get in touch with the top influencers from across India and Middle East.</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import downLoadAPK from '@/utils/index'
export default {
  mounted() {
    new this.$wow.WOW({
      live: true
    }).init()
  },
  methods: {
    downLoad() {
      downLoadAPK()
    }
  }

}
</script>

<style lang="scss" scoped src="./content.scss">

</style>