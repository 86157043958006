<template>
  <div class="mdetail">
    <div class="mbl_mdetailheadbg">
      <div class="detail_title">{{ deteil_title }}</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: ['deteil_title'],
  methods: {
    ...mapMutations(['changenavshow']),
    gotohome() {
      this.$router.push('/mobile')
    },
    gotonav() {
      this.$router.push('/nav')
    },
  },
}
</script>

<style lang="scss" scoped src="./mdateil.scss">

</style>