<template>
  <!-- @mousewheel="chslide" -->
  <div class="mobile_home" @mousewheel="chslide" >
    <Mobileheadernav ref="mbheadernav"></Mobileheadernav>
    <router-view></router-view>
    <Mobilefooter></Mobilefooter>
  </div>
</template>

<script>
import Mobileheadernav from '@/components/Mobileheadernav/Mobileheadernav'
import Mobilefooter from '@/components/MobileFooter/Mobilefooter.vue'
export default {
  components: {
    Mobileheadernav,
    Mobilefooter,
  },
  methods:{
    chslide(){
      console.log('ssss');
      this.$refs.mbheadernav.mobchangebg()
    }
  }
}
</script>

<style lang="scss" scoped src="./mobile.scss">
</style>